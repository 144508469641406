import { MultiFileDropzone } from '@/common/presentation/components/ImageDropZone/multifile-dropzone';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { TableCell, TableRow } from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '@/modules/residents/infrastructure/types/documents';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { File as FileIconLucide, Loader2, Save } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';

interface Props {
  resident_id: string;
  document: any;
  residentDocumentsCount: number;
}

type Version = {
  id: string;
  created_at: string;
};

const formSchema = z.object({
  comments: z.string().optional(),
  hard_copy: z.boolean().optional(),
  files: z
    .array(
      z
        .instanceof(File)
        .refine((file) => Object.keys(ACCEPTED_FILE_TYPES).includes(file.type), {
          message: 'Invalid file type.'
        })
        .refine((file) => file.size <= MAX_FILE_SIZE, {
          message: 'Max file size is 2MB.'
        })
    )
    .min(1, { message: 'File is required.' })
});

type FormValues = z.infer<typeof formSchema>;

export const CategoryItem = ({ document, resident_id, residentDocumentsCount }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { onOpen } = useHistoryDocuments();

  const versions = useMemo(() => {
    const parentVersions = document?.parent_documents || [];

    if (document?.id) {
      return [...parentVersions, document].sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    }

    return parentVersions;
  }, [document]);

  const form = useForm<FormValues>({
    defaultValues: {
      comments: versions?.[versions.length - 1]?.comments ?? '',
      hard_copy: versions?.[versions.length - 1]?.other_information?.hard_copy ?? false,
      files: []
    },
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (formValues: any) => {
    const parentId = versions
    .sort((a: Version, b: Version) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())[0]?.id ?? null;
    setIsLoading(true);
    const data = {
      key: document.key,
      label: document.label,
      comments: formValues.comments,
      category: document.category,
      parent_document_id: parentId|| null,
      resident_id,
      other_information: {
        hard_copy: formValues.hard_copy,
        digital_signature_on_file: false
      }
    };

    const stringifiedData = JSON.stringify(data);

    const payload = {
      data: stringifiedData,
      files: formValues.files
    };

    const response = await dispatch(residentActions.saveResidentDocument(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      form.reset();
      dispatch(residentActions.getResidentById(resident_id));
    }
    setIsLoading(false);
  };

  const handleViewDocument = () => {
    window.open(versions?.[versions.length - 1]?.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Form {...form}>
      <TableRow>
        <TableCell>{document.label}</TableCell>

        <TableCell>
          <FormField
            control={form.control}
            name="comments"
            render={({ field }) => (
              <FormItem>
                <Textarea {...field} className="h-10 min-h-10" />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>
          <FormField
            control={form.control}
            name="hard_copy"
            render={({ field }) => (
              <FormItem>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>
          <Button
            className="flex items-center space-x-2"
            variant="ghost"
            onClick={() => {
              onOpen(versions);
            }}
            disabled={versions?.length === 0}
          >
            {versions?.length || 0} Version{versions?.length === 1 ? '' : 's'}
          </Button>
        </TableCell>

        <TableCell>
          <div className="flex gap-2 w-full items-center">
            <Button
              size={'icon'}
              className={cn(
                document.url !== null
                  ? 'border-green-500  text-green-500 hover:bg-green-500 hover:text-white'
                  : 'border-red-500  text-red-500 hover:bg-red-500 hover:text-white'
              )}
              variant={'outline'}
              onClick={handleViewDocument}
              disabled={document?.url === null}
            >
              <FileIconLucide className="size-4" />
            </Button>
            <FormField
              control={form.control}
              name="files"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <MultiFileDropzone
                      className={`w-auto outline-none min-h-[40px] min-w-[250px] ${
                        isLoading ? 'bg-gray-200 cursor-not-allowed opacity-50' : ''
                      }`}
                      value={field.value}
                      onChange={field.onChange}
                      dropzoneOptions={{
                        accept: ACCEPTED_FILE_TYPES,
                        maxSize: MAX_FILE_SIZE,
                        maxFiles: residentDocumentsCount === 0 ? 1 : undefined
                      }}
                      width={300}
                      disabled={isLoading} // Deshabilita la interacción
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {form.formState.isDirty && form.watch('files').length > 0 && (
              <Button
                variant="default"
                size="icon"
                className="bg-background border border-green-600 hover:bg-background text-green-600"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {isLoading ? <Loader2 className="size-4 animate-spin" /> : <Save className="size-4" />}
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    </Form>
  );
};
